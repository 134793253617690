.App {
    text-align: center;
    height: 100vh;
    overflow-y: auto;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.App-logo {
    pointer-events: none;
    margin-right: 20px;
}

.App-header {
    height: 80px;
    z-index: 10;
    box-shadow: 0 6px 15px rgb(13 51 32 / 10%);
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    background-color: #FFFFFF;
    display: flex;
    padding: 24px;
}

.App-header img {
    display: flex;
    height: 100%;
}

.title-wrapper {
    text-align: left;
}

.title-wrapper h1 {
    margin-top: 8px;
    font-size: 20px;
}

.buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.input {
    border: 1px none black;
    border-bottom-style: solid;
    border-radius: 0;
    padding: 10px 0;
}

.error{
    border: 1px none #f44336;
    border-bottom-style: solid;
}

.auth-error-message{
    min-height: 40px;
}

.input:focus {
    outline: none;
}

.MuiButton-contained.button-next {
    border-radius: 20px;
    background-color: #5BA646;
    color: #FFFFFF;
    font-size: 16px;
    margin-top: 20px;
}

.MuiButton-contained.button-next:hover {
    background-color: #398a24;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.auth-label {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #768692;
    font-size: 16px;
}

.App-link {
    color: #61dafb;
}
